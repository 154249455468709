<template>
  <ion-page>
    <div class="l-wrapper static">
      <div class="l-login">
        <h1 class="l-logo">
          <img
            class="logo"
            src="../../components/slide-1.png"
            alt="로고" />
        </h1>
        <div class="l-form">
          <ion-item fill="outline">
            <ion-label position="stacked">
              ID
            </ion-label>
            <ion-input
              placeholder="아이디"
              v-model="loginInfo.username" />
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="stacked">
              PASSWORD
            </ion-label>
            <ion-input
              type="password"
              placeholder="비밀번호"
              v-model="loginInfo.password" />
          </ion-item>
        </div>
        <div class="l-btn">
          <vp-button
            expand="full"
            @click="handleLogin">
            로그인
          </vp-button>
        </div>
      </div>
    </div>
  </ion-page>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { 
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  useIonRouter,
  toastController
} from '@ionic/vue'
import { reactive } from 'vue'
import { useStore } from 'vuex'
import md5 from 'js-md5'

//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const store = useStore()
const ionRouter = useIonRouter()

const loginInfo = reactive({
  username: undefined,
  password: undefined
})

// agency_name - 대리점명
const handleLogin = async () => {
  const param = Object.assign({}, loginInfo)
  param.password = md5(param.password)

  const response = await store.dispatch('User/fetchLogin', param)
  if (response && response.data.token) { 
    ionRouter.replace('/')
  } else {
    const toast = await toastController.create({
      message: response.msg,
      duration: 1000,
      position: 'bottom'
    })

    await toast.present()
  }
}

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------

</script>

<style scoped>
ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --border-color: transparent;
  --ion-safe-area-right: 0;
}
.l-login {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.l-logo {
  text-align: center;
  padding-bottom: 10px;
}
.logo {
  width: 100px;
  height: 100px;
  text-align: center;
}
.l-btn {
  padding-top: 10px;
  text-align: right;
}
</style>
